@keyframes flash {

  0%,
  100% {
    background-color: transparent;
    border-color: initial;
  }

  50% {
    background-color: #5eb6be;
    border-color: #3b747a;
  }
}

.flash-effect {
  animation: flash 2s;
}

* {
  padding: 0;
  margin: 0;
}

body {
  position: absolute;
}

body,
.container {
  width: 100%;
  min-height: 100vh;
}


button {
  font-family: Inter, serif;
  font-style: normal;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0;
  background-color: #38757b;
  gap: 20px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  padding: 10px 0;
}


.confirmation-section {
  text-align: center;
  margin-top: 10px;
}

.edit-buttons {
  display: flex;
  justify-content: center;
  /* Center the buttons horizontally */
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  font-size: 12px;
}

.tooltip-text {
  visibility: hidden;
  background-color: #38757b;
  color: #fff;
  text-align: start;
  border-radius: 6px;
  padding: 15px;
  width: fit-content;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -90px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.unnamed-character-style-1 {
  font-family: Inter, serif;
  font-style: Italic;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0;
  color: #ffffff;
}

.unnamed-character-style-3 {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0;
  color: #4E4E4E;
}

.select {
  margin-left: 10px;
}

.edit {
  /*background-image: url("./static/img/edit.jpg");*/
  margin: 0 auto 20px auto;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
}

.content_container {
  flex: auto;
  display: flex;
  justify-content: center;
  align-content: center;
}

.content_container.page_index {
  align-items: center;
}


.content_container_inner {
  text-align: right;
}

.content_container.page_index .content_container_inner {
  margin-left: -90px;
}

.inner_logo {
  padding: 70px 30px;
  text-align: center;
}

.buttons_container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-primary {
  display: inline-block;
  width: 213px;
  color: white;
  background-color: #38757B;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;
}

.btn-primary.font_large {
  font-size: 24px;
  font-weight: 500;
}

button {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;

  width: 213px;
  color: white;
  background-color: #38757B;
  padding: 17px;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;
  border: none;
  display: flex;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
}

button:hover {
  background-color: #32666b;
}

.btn_line {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;

  width: 310px;
  color: white;
  background-color: white;
  color: #38757B;
  border: 1px solid #38757B;
  padding: 17px;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.btn_line:hover {
  background-color: white;
  border: 1px solid #D4D4D4;
}

textarea {
  border: none;
  background-color: #FAFAFA;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  padding: 10px;
  text-align: center;
  border-radius: 25px;
  max-width: 80%;
  overflow-y: hidden;
  resize: none;
}

textarea::placeholder {
  color: #38757B;
  opacity: 1;
}

textarea:focus {
  outline: none !important;
  border: 1px solid rgb(199, 199, 199);
}

.editable {
  text-align: center;
}

.tb_noteditable {
  resize: none;
  color: #38757b;
  border: 1px solid #38757b;
}

.page_where_live {
  padding: 50px 20px;
  justify-content: center;
}

.page_where_live h2 {
  color: #4E4E4E;
  font-weight: 300;
  font-size: 38px;
}

.page_where_live .select {
  display: inline-grid;
  min-width: 150px;
}

.div_location {
  font-weight: 300;
  font-size: 25px;
  color: #d3d3d3;
  text-align: center;
}

.boldtext {
  font-weight: 600;
}

.ai-response {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: relative;
  min-height: 200px; */
  justify-content: center;
}

.div_p3_airesponse {
  color: #38757B;
  line-height: 26px;
  text-align: center;
  margin-top: 20px;
  max-width: 60%;
}

.div_p3_correct_label {
  color: #38757B;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  width: 50%;
  display: inline-block;
  font-size: small;
}

.div_p3_statement_label {
  color: #38757B;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  padding-right: 40%;
  margin-top: 10px;
}

.div_p3_clarification {
  margin: 30px 0;
}

.returndiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.returndiv-client {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.returndiv-client textarea {
  width: 60%;
}

.div_p4_text {
  max-width: 600px;
  color: #38757B;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin: 50px auto;
}

.div_p4_text p {
  margin: 10px 0;
}

.div_question {
  max-width: 600px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin: 50px auto;
  color: #d3d3d3;
}

/*.loader {
  text-align: center;
}
*/
/* StartScreen.css */

.start-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
}

.logo-container {
  display: flex;
  flex-direction: column;
}

.logo-container h1 {
  font-size: 48px;
  margin: 0;
  color: #333;
}

.logo-container h1 span {
  color: #009966;
}

.logo-container p {
  font-size: 24px;
  margin-top: 10px;
  color: #999;
}

.start-button {
  font-size: 26px;
  margin-top: 15%;
}

.non-selectable {
  user-select: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px 100px;
  align-items: center;
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Arial', sans-serif;
  text-align: center;
  font-size: 0.875rem;
  border-top: 1px solid #ddd;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

footer img {
  width: 150px;
}

footer a {
  color: #38757b;
  text-decoration: none;
  margin: 0 5px;
}

footer a:hover {
  text-decoration: underline;
}

.disclaimer {
  width: 50%;
  font-size: 9px;
}

.btn_line:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.btn_line_two {
  align-self: flex-end;
}

.btn_line_two_continue {
  align-self: flex-end;
  width: 280px;
}

.btn_line_two:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.modalContainer {
  padding: 20px;
}

.modalContainer h1 {
  margin-bottom: 20px;
}

.modalContainer p {
  padding: 10px;
}

.bookmark-menu {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  max-width: 270px;
  margin-top: 10px;
}

.bookmark-menu button {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  /* Szín beállítása. */
  font-size: 16px;
  padding: 8px;
  justify-content: left;
}

.bookmark-delete-button {
  margin: 0;
  width: auto;
}

.bookmark-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
  /* Max height set to 80% of the viewport height */
  overflow-y: auto;
  /* Enables vertical scrolling */
}

.bookmark-menu li {
  overflow: auto;
  text-overflow: ellipsis;
  padding: 8px 24px 8px 8px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;

}

.bookmark-menu li i {
  margin-left: 8px;
}

.bookmark-menu li button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff0000;
}

.fa-bars {
  color: black;
}

.textarea-container {
  position: relative;
  display: inline-block;
}

.btn-edit-clarify {
  top: 5px;
  left: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
  border-radius: 40px;
  color: #000;
  width: auto;
  margin: 0px;
}

.btn-edit-clarify:hover {
  background-color: white;
  color: #38757b;
}

.pagefour {
  text-align: center;
}

.pagefour .editable {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.customModalOverlay {
  position: fixed;
  /* or absolute */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* High z-index to ensure it's on top of other content */
}

.customModalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 40%;
  max-height: 60vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: auto;
  z-index: 1001;
  background-color: white;
  padding: 20px;
  /* Should be higher than the overlay z-index */
}

.modalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.modalContainer {
  padding: 20px;
  text-align: left;
  flex: 1;
}

.okButton {
  margin: 10px;
}

.buttons-wrap {
  display: flex;
  flex-direction: column;
}

.start-over-container {
  display: inline-block;
}

.edit-button,
.clarify_button,
.start-over {
  align-self: center;
}

.regenerate-button {
  display: flex;
  justify-content: space-around;
}

.regen-div {
  display: flex;
  justify-content: center;
}

.page-three-container {
  position: relative;
}

.ai-assistant-container {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  max-width: 60%;
  align-items: center;
  /* max-height: 300px; */
}

.ai-assistant-container img {
  border-radius: 70px;
}

.assistant-image {
  width: 100px;
  /* Adjust as necessary */
  height: 100%;
}

.speech-bubble {
  position: relative;
  background: #f0f9ff;
  border-radius: .4em;
  border: 2px solid #38757B;
  padding: 10px;
  margin: 1em 0 3em;
  color: #38757B;
  line-height: 26px;
  text-align: center;
  margin-top: 20px;
  /* max-width: 60%; */
  /* You can adjust this as needed */
}


.speech-bubble:after,
.speech-bubble:before {
  content: '';
  position: absolute;
  top: 50%;
  /* Aligns the tail with the vertical middle of the bubble */
  left: -20px;
  /* Adjust this value to move the tail closer or further from the bubble */
  border-style: solid;
  border-width: 10px 20px 10px 0;
  /* Creates the triangle for the tail */
  border-color: transparent #f0f9ff transparent transparent;
  /* The second value is the color of the bubble */
  transform: translateY(-50%);
  /* Ensures the tail is centered with respect to the bubble's height */
}

.speech-bubble:before {
  border-width: 12px 22px 12px 0;
  /* Slightly larger to create the border effect */
  border-color: transparent #38757B transparent transparent;
  /* The second value is the color of the bubble's border */
  left: -22px;
  /* Aligns the border of the tail with the border of the bubble */
  z-index: -1;
  /* Ensures the border of the tail is behind the bubble */
}

.main-content {
  margin-bottom: 111px;
}

.result-div {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  right: 10%;
  left: 10%;
  width: 80%;
}

.result-buttons {
  display: flex;
  flex-direction: row;
}

.result-buttons button {
  width: auto;
  background-color: #fff;
  color: #696969;
  margin: 10px;
}

.social-icons button {
  width: auto;
  margin: 10px;
}

.social-icons {
  display: flex;
  flex-direction: row;
}

.social-icons .social-icon circle {
  fill: gray;
  /* Default fill color for all icons */
  transition: fill 0.3s ease;
  /* Transition for fill color */
}

/* LinkedIn */
.social-icons .linkedin-icon:hover circle {
  fill: #0077b5 !important;
  /* LinkedIn Blue */
}

/* Twitter */
.social-icons .twitter-icon:hover circle {
  fill: #1DA1F2 !important;
  /* Twitter Blue */
}

/* Reddit */
.social-icons .reddit-icon:hover circle {
  fill: #FF5700 !important;
  /* Reddit Orange */
}

/* Facebook */
.social-icons .facebook-icon:hover circle {
  fill: #4267B2 !important;
  /* Facebook Blue */
}

/* Email */
.social-icons .email-icon:hover circle {
  fill: rgb(127, 127, 127) !important;
  /* Email Red */
}

/* Telegram */
.social-icons .telegram-icon:hover circle {
  fill: #2CA5E0 !important;
  /* Telegram Blue */
}

/* Messenger */
.social-icons .messenger-icon:hover circle {
  fill: #0084FF !important;
  /* Messenger Blue */
}

/* WhatsApp */
.social-icons .whatsapp-icon:hover circle {
  fill: #25D366 !important;
  /* WhatsApp Green */
}

/* Viber */
.social-icons .viber-icon:hover circle {
  fill: #665CAC !important;
  /* Viber Purple */
}

.messenger-icon {
  display: none;
}


.icon-button {
  border: 1px solid #38757b;
  color: #696969;
  border-radius: 50px;
}

/* Spinner container styles */
.spinner-container {
  position: fixed;
  /* Fixed position to cover the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 117, 123, 0.663);
  /* Semi-transparent background */
  backdrop-filter: blur(5px);
  /* Blur effect */
  z-index: 9999;
  /* High z-index to make sure it's on top */
}

/* Spinner GIF styles */
.spinner {
  width: 50px;
  /* Adjust as needed */
  height: 50px;
  /* Adjust as needed */
}

/* Text message styles */
.spinner-text {
  text-align: center;
  margin-top: 20px;
  /* Space between spinner and text */
  font-size: 1em;
  padding: 20px;
  color: #ffffff;
  /* Adjust color as needed */
}

@media (max-width: 1200px) {
  .edit-buttons {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 900px) {

  .main-content {
    margin-bottom: 0px;
  }

  .selector-container,
  .options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5%;
    position: relative;
    z-index: 0;
  }

  .buttons {
    margin-left: 10%;
    margin-right: 10%;
  }

  .edit-buttons {
    margin-left: 5%;
    margin-right: 5%;
  }

  .customModalContent {
    max-width: 85%;
    width: 70%;
  }

  .start-over {
    align-self: center;
  }

  .ai-assistant-container {
    max-width: 80%;
  }

  .btn_line_two {
    align-self: center;
  }

  .btn_line_two_continue {
    align-self: center;
  }

}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}

.header button {
  margin: 5px;
  width: 32px;
  height: 32px;
}

@media (max-width: 600px) {

  .buttons,
  .edit-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .btn_line_two_continue {
    align-self: center;
  }

  .div_p3_correct_label {
    width: 80%;
  }

  .div_p4_text {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: auto;
    margin-left: 5px;
  }

  .div_location {
    padding: 10px;
  }

  .div_question {
    max-width: 80%;
  }

  .returndiv-client textarea {
    width: 80%;
  }

  .customModalContent {
    max-height: 70vh;
  }

  .customModalContent p {
    font-size: 14px;
  }

  .pagefour .editable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header button {
    margin: 2px;
    width: 25px;
    height: 25px;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }

  .footer {
    position: relative;
    z-index: 0;
  }

  .ai-response {
    align-items: flex-start;
    margin-left: 15px;
  }

  .speech-bubble p {
    line-height: normal;
  }

  .ai-assistant-container {
    align-items: flex-start;
    max-width: 95%;
  }

  /*.assistant-image {
    width: 20%;
  }*/

  .speech-bubble:after,
  .speech-bubble:before {
    top: 25%;
  }

  /* .spinner {
    position: absolute;
    top: 30%;
    left: 46%;
  } */

  .result-div {
    flex-direction: column;
  }

  .messenger-icon {
    display: block;
  }

  .social-icons button {
    margin: 5px;
  }

}